.Home-Area
{
    margin-bottom: 500px;
}

.heading-tile
{
    height: fit-content;
    /*background-color: rgba(244, 244, 244, 0.3);*/
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-content: center;
    justify-content: center;
    /*box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);*/
    /*border-radius: 20px;*/
}

.col
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.heading-tile img
{ 
    border-radius: 20px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
}