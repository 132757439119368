.carousel-control-prev{
    background-color: gray;
    width: 100px;
    height: 100px;
    background-color: #3498db;
    opacity: 10%;
    border-radius: 50%;
    margin-top: 20%;
}

.carousel-control-next{
    background-color: gray;
    width: 100px;
    height: 100px;
    background-color: #3498db;
    opacity: 10%;
    border-radius: 50%;
    margin-top: 20%;
}