.pdfListItem {
    max-width: 400px;
}
.pdfListItem:hover {
    cursor: pointer;
    color: rgba(32, 32, 187, 0.73);
    background-color: lightgrey;
}

.deleteButton:hover {
    cursor: pointer;
    /*background-color: rgba(32, 32, 187, 0.73);*/
}