.arco-layout-sider
{
    border-radius: 40px;
    box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.1);
    border: 0.1px solid lightgray;
}

.easy-email-editor-tabWrapper
{
    box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.arco-card
{
    border: 0;
    overflow: visible;
}

.arco-layout
{
    padding: 0 30px;
    overflow: visible;
    justify-content: space-between;
}

.arco-collapse-item-content.arco-collapse-item-content-expanded
{
    border-radius: 20px;
}