#imgHeader {
    max-width: 150px;
}

#embeddedDocument {
    background-color: white;
    max-width: 250px;
    height: 120px;
    alt: "pdf"
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

.refreshDataButton:active {
    color: green;
    box-shadow: 2px 2px;
    /*font-size: 10px;*/
    padding-top: 2px;
    padding-left: 2px;
}

#headerText {
    font-size: 14px;
    font-weight: bold;
}

#bodyText {
    font-size: 13px;
    max-width: 250px;
    padding-top: 10px;
    padding-bottom: 5px;
}

#buttonTextQuickReply {
    color: rgb(9, 153, 235);
    /*font-weight: bold;*/
    /*border: 1px solid grey;*/
    border-radius: 10px;
    max-width: 180px;
    text-align: center;
    margin-top: 5px;
}

#buttonTextQuickReply:hover #buttonTextUrl:hover {
    cursor: pointer;
    background-color: lightgrey;
}

#buttonTextUrl {
    color: rgb(9, 153, 235);
    cursor: pointer;
    font-weight: bold;
    /*border: 1px solid grey;*/
    /*border-radius: 10px;*/
    /*max-width: 200px;*/
    text-align: center;
    margin-top: 5px;
}

#footerText {
    font-size: 12px;
    color: grey;
}

.chat-container {
    width: 300px;
    height: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-y: scroll;
    margin: 20px auto;
    padding: 10px;
}

/* Styles for chat messages */
.message {
    background-color: #e1ffc7;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 10px;
}

/* Styles for the input and button */
.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.chat-input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 10px;
}

.send-button {
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
}