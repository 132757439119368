/* SubMenusComponent.css */

.menu-container {
    /*width: 200px; !* Adjust as needed *!*/
    width: 300px;
}

.menu-title {
    cursor: pointer;
    padding: 10px;
    /*background-color: #f0f0f0;*/
    /*border: 1px solid #ddd;*/
    border-bottom: 1px solid #ddd;
    /*justify-content: space-between;*/
    margin-top: 5px;
    text-align: left;
}

.sub-menu {
    overflow: hidden;
    max-height: 0; /* Start closed */
    transition: max-height 0.3s ease-out; /* Smooth opening effect */
}

.sub-menu.open {
    max-height: 200px; /* Adjust as needed for open state */
}

.sub-menu.closed {
    max-height: 0;
}

.sub-menu-item {
    padding: 5px 10px;
    border-bottom: 1px solid #eee;
}

.caret {
    display: inline-block;
    margin-left: 5px; /* Space out the caret from the text */
    transition: transform 0.3s ease; /* Smooth rotation effect */
}

.caret.up {
    transform: rotate(-180deg); /* Flip caret up */
}
