/* HorizontalSubMenuComponent.css */

.submenu-container {
  display: flex; /* Align submenu items horizontally */
  border-bottom: 1px solid #ccc; /* A line under the submenu for visual separation */
  /*border-width: 100px;*/
}

.submenu-item {
  padding: 10px 20px; /* Adjust padding as needed */
  cursor: pointer; /* Change cursor to indicate interactivity */
}

.submenu-item.active {
  border-bottom: 2px solid blue; /* Highlight the active submenu item */
}

.content-container {
  margin-top: 20px; /* Spacing between the submenu and content */
}
