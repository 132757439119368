/*templateEditDeleteButton*/
/*.image-container {*/
/*    position: relative;*/
/*    width: 300px;*/
/*    height: 200px;*/
/*}*/

/*.image-container img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
/*.image-container:hover {*/
/*    cursor: default;*/
/*}*/
.image-container:hover{
    /*cursor: pointer;*/
    opacity: 0.7;
    /*transition: transform 1s ease; !* This transitions the transform property *!*/
}

/*.image-container:hover {*/
/*    transform: scale(1.1); !* Example effect - scale the image on hover *!*/
/*}*/
#specialbutton:hover {
    display: block;
}
#specialbuttonChild {
    cursor: pointer;
    border: 1px solid;
    /*opacity: 1 !important;*/
    /*backgroundColor: red;*/
    min-width: 100px;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    border-radius: 20px;
    margin-left: 20px;
    opacity: 1;
}

.image-container #specialbutton {
    display: none;
}

.image-container:hover #specialbutton {
    display: block;
    position: absolute;
    top: 400px;
    left: 200px;
    display: flex;
    z-index: 1000;
}

.image-container:hover .buttons {
    opacity: 1;
}
