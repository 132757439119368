._blockItem_1ajtj_6 {
    /*max-width: 20px;*/
    /*width:40px;*/
    margin: 5px;
    padding: 5px;
}

._blockItem_1ajtj_6 ._blockItemContainer_1ajtj_16 {
    width: 70px;
    height: 70px;
}

.arco-layout-sider-children {
    max-height: 500px;
    /*max-width: 300px;*/
}

.arco-collapse-item-content-box {
    margin-top: -10px;
}

.arco-layout {
    padding: 5px;
    max-height: 800px;
    overflow: scroll;
}

.easy-email-editor {
    /*max-height: 500px;*/
    /*overflow: scroll;*/
}


._largeTabsHeader_d2sio_1 {
    /*background-color: #aeb6ae;*/
    border-radius: 20px;
}

.arco-collapse-item-content-box {
    padding: 0px;
    max-width: 300px;
}

.arco-row arco-row-align-start {
    justify-content: center;
}

.arco-row-justify-start {
    justify-content: center;
}

.arco-card-body {
    /*background-color: lightgrey;*/
    border-radius: 50px;
    /*height: 63vh;*/
    /*vertical-align: center;*/
    /*padding-top:50px;*/
    /*padding-bottom:50px;*/
}

.arco-layout-sider {
    max-height: 600px;
    /*max-width: 100px;*/
    /*width: 100px;*/
}

. arco-layout-sider-light {
    /*max-width: 150px;*/
}

/*#arco-layout-sider arco-layout-sider-light{*/
/*    width: 200px;*/
/*    min-width: 200px;*/
/*}*/