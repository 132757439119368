
.blog-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: left;
}

.article-block {
    cursor: pointer;
    border: 1px solid #ddd;
    padding: 20px;
    /*width: calc(33% - 20px); !* Adjust width for 3 columns layout, minus gap *!*/
    box-shadow: 0px 0px 5px #ddd;
    border-radius: 30px;
    margin: 10px;
    width: 30%; /* Adjust the width as per your design */
    transition: transform 0.2s; /* Animation */
}

.article-block h2 {
    margin-top: 0;
}

.article-block p {
    color: #555;
}
