.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    width: 20%;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.App-header2 {
    /*background-color: lightgray;*/
    /*min-height: 5vh;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(0px + 1.9vmin);
    color: dimgray;
    /*margin-bottom: 5vh;*/
    text-align: center;
}

.wrapper-class {
    border: 1px solid #ccc;
    height: 300px;
    padding: 1rem 1rem 100px;
}

.editor-class {
    /*background-color:lightgray;*/
    padding: 1rem;
    border: 1px solid #ccc;
}

.toolbar-class {
    border: 1px solid #ccc;
}

.btnsidebar {
    font: inherit;
    border: 1;
    /*outline: 0;*/
    /*transition: all 200ms ease-in;*/
    cursor: pointer;
    /*background: #6279AB;*/
    /*color: #fff;*/
    /*width: 120px;*/
    /*height: 62px;*/
    /*box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);*/
    border-radius: 5px 5px 5px 5px;
    padding: 10px 10px;
    transition: background-color 0.3s; /* Add smooth transition */
}

.btnsidebar:hover {
    background-color: red; /* Change color on hover */
}

/*@keyframes colorTransition {*/
/*  from {*/
/*    background-color: blue;  !* Starting color *!*/
/*  }*/
/*  to {*/
/*    background-color: lightblue;  !* Ending color *!*/
/*  }*/
/*}*/

/* Apply the animation to your app container */
.app-container {
    /*animation: colorTransition 5s ease-in-out infinite;  !* Adjust the duration and other properties as needed *!*/
}