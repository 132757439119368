/* styles.css */
.image-slide-in {
    width: 60px; /* Adjust based on your image */
    opacity: 0; /* Start invisible */
    position: fixed; /* Keep the image in the same place on the screen */
    right: -150px; /* Start off-screen */
    bottom: 0.11%; /* Adjust based on desired location */
    transition: all 1s ease-out; /* Smooth transition for sliding in */
}

/* styles.css */
.image-slide-in.slide-in {
    right: 0.1%; /* Adjust as needed - this is the end position */
    opacity: 1; /* Make the image fully visible */
}