.row-hover:hover {
    background-color: #ecebeb; /* Change this to your desired hover color */
}

/* DynamicTable.css */
.dynamic-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;
}

.table-row-header {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
    /*margin-left: -10px;*/
    text-align: left;
}

.table-row {
    /*width: 100%;*/
    min-width: 1000px;
    cursor: pointer;
    display: inline-flex;
    justify-content: left;
    margin-bottom: 10px;
    border-radius: 50px;
    height: 60px;
    /*border: 1px solid grey;*/
    text-align: left;
    /*border: 1px solid #CCCCCC; !* Light grey border *!*/
    box-shadow: 0px 0px 10px rgb(178 169 169 / 50%); /* White glow */
    /*background-color: #F0F0F0; !* Light background for contrast *!*/
}

.table-row:hover {
    background-color: #edefed;
}

.table-cell {
    cursor: pointer;
    padding: 10px;
    margin: 8px 5px;
    /*border: 1px solid #ddd;*/
    /*border-radius: 4px;*/
    width: 80px;
}

.add-row-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.add-row-btn:hover {
    background-color: #45a049;
}

/*Test functionality over here*/
.hover-div {
    /* Basic styling for your div */
    position: relative;
    cursor: pointer;
    z-index: 10;
    /* Other styles as needed */
}

.cursor-text {
    position: fixed;
    pointer-events: none; /* Ensures the text doesn't interfere with other mouse events */
    z-index: 10; /* Ensure it appears above other content */
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    padding: 5px;
    border: 1px solid #ddd;
    /* Additional styling as needed */
}
