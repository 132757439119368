#mytemplateswhatsapp{
    cursor: pointer;
}
#specialbuttonChild {
    cursor: pointer;
    border: 1px solid;
    margin-top: 10px;
    margin-bottom: 0px;
    /*opacity: 1 !important;*/
    /*backgroundColor: red;*/
    min-width: 100px;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    border-radius: 20px;
    /*margin-left: 20px;*/
    opacity: 1;
}

#specialbuttonContainer{
    cursor: pointer;
}
#specialbuttonContainer #specialbutton {
    display: none;
}

#specialbuttonContainer:hover #specialbutton {
    display: flex;
    position: relative;
    /*top: -400px;*/
    /*left: 200px;*/
    z-index: 1000;
}

#specialbuttonContainer:hover {
    opacity: 0.7;
}

#specialbuttonContainer:hover .buttons {
    opacity: 1;
}
