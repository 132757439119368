code:hover {
    background-color: rgba(242, 243, 242, 0.96);
    cursor: pointer;
    border-radius: 30px;
}

/* Modal.css */
.modal-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-body {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    /*z-index: 2;*/
}
