.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    color: blue;
    text-align: center;
    margin: 10px 0px;
    position: absolute;
    z-index: 1;
    left: -110px; /* Adjust this value to control the distance from the cursor */
    top: 0%;
    transform: translateY(-50%);
}

.tooltip .tooltiptext2 {
    visibility: hidden;
    width: 120px;
    color: red;
    text-align: center;
    margin: 10px 0px;
    position: absolute;
    z-index: 1000;
    left: -172px; /* Adjust this value to control the distance from the cursor */
    top: 0%;
    transform: translateY(-50%);
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip:hover .tooltiptext2 {
    visibility: visible;
}